<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-09-08 15:14:23
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 10:43:38
 * @FilePath: \official_website\src\views\products\details\Pelleter.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 制片机 -->
<template>
  <div id="pelleter">
    <Header></Header>
    <div class="pelleter-conter">
      <div class="pelleter-img">
        <Breadcrumb :productType="productType" :productName="productName"></Breadcrumb>
        <div class="illustrate-box">
          <div class="title wow animate__animated animate__fadeInUp">
            液基制片染色一体机<br />
            TP-48
          </div>

          <div class="line"></div>
          <ul class="keywords wow animate__animated animate__fadeInUp">
            <li>可视化</li>
            <li>个性化</li>
            <li>大通量</li>
            <li>自动化</li>
            <li>应用广</li>
          </ul>
        </div>
        <div class="product-img">
          <!-- <img src="@/assets/image/product/film-1.png" alt="" /> -->
        </div>
      </div>
      <div class="pelleter-description">
        <div class="parameter">
          <div class="parameter-item">
            <div class="parameter-box parameter-one"></div>
            <div class="parameter-box parameter-text wow animate__animated animate__fadeInUp">
              <div class="title">可视化</div>
              <div class="detail">微电脑自动控制,中文界面,液晶显示，触摸屏操作，简捷、直观、方便。</div>
            </div>
          </div>
          <div class="parameter-item">
            <div class="parameter-box parameter-text wow animate__animated animate__fadeInUp">
              <div class="title">个性化</div>
              <div class="detail">多种运行模式，满足客户多种选择的同时自定义染色模式,可自由调节染色时间,满足客户的个体化需求。</div>
            </div>
            <div class="parameter-box parameter-two"></div>
          </div>
        </div>
        <div class="characteristic">
          <ul class="feature-box">
            <li class="feature-item">
              <div class="left">
                <img class="wow animate__animated animate__fadeInUp" src="@/assets/image/patholog/icon-1.png" alt="" />
              </div>
              <div class="right wow animate__animated animate__fadeInUp">
                <div class="title">大通量</div>
                <div class="desc">
                  全自动批量处理标本,1-48人份多种选择,轻松满足临床客户需求。
                </div>
              </div>
            </li>
            <li class="feature-item">
              <div class="left">
                <img class="wow animate__animated animate__fadeInUp" src="@/assets/image/patholog/icon-1.png" alt="" />
              </div>
              <div class="right wow animate__animated animate__fadeInUp">
                <div class="title">自动化</div>
                <div class="desc">
                  全程微电脑控制，一键操作，无需人工值守，自动完成制片染色过程，减少人为因素对制片效果的影响，染色流程标准化，定时定量保证染色效果一致性。
                </div>
              </div>
            </li>
            <li class="feature-item">
              <div class="left">
                <img class="wow animate__animated animate__fadeInUp" src="@/assets/image/patholog/icon-1.png" alt="" />
              </div>
              <div class="right wow animate__animated animate__fadeInUp">
                <div class="title ">应用广</div>
                <div class="desc">
                  在满足妇科标本的同时,亦可应用于非妇科标本的制作(包括:痰液细胞、尿液细胞、针吸细胞甲状腺穿刺液、浆膜腔积液、内窥镜刷检及灌洗液、乳腺溢液等)
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "@/views/componets/Header.vue";
import Footer from "@/views/componets/Footer.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
import Breadcrumb from "@/views/componets/Breadcrumb.vue";
import api from "../../../api/api";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    RelatedProduct,
    Breadcrumb
  },
  data() {
    return {
      productType: "产品",
      productName: "液基制片染色一体机",
      relatProd: [
        {
          title: "液基膜式制片机",
          src: require("@/assets/image/product/film-2.png"),
          path: "/product/film",
        },
        {
          title: "液基细胞学TCT诊断试剂",
          src: require("@/assets/image/product/reagent-1.png"),
          path: "/product/tctReagent",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
     this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
     $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
     // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#pelleter {
  width: 100%;
  height: 100%;
  .pelleter-conter {
    padding-top: 5rem;
    .pelleter-img {
      position: relative;
      height: 50rem;
      background-image: url("../../../assets/image/product-detail/product-bg.png");
      background-size: cover;
      // img {
      //  position: absolute;
      //  left: 45%;
      //  top: 5%;
      // }
      .illustrate-box {
        position: absolute;
        left: 18%;
        top: 30%;
        width: 21.875rem;
        .title {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 2.8125rem;
        }
        .line {
          width: 4.25rem;
          height: 3px;
          margin-bottom: 1.25rem;
          background: #177a73;
        }
        .keywords {
          display: flex;
          li {
            width: 18.4%;
            height: 2.1875rem;
            line-height: 2.1875rem;
            margin-right: 2%;
            color: #fff;
            background: #177a73;
            border-radius: 2.1875rem;
            text-align: center;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
      .product-img {
        position: absolute;
        left: 45%;
        top: 12%;
        width: 50%;
        height: 75%;
        background-image: url("../../../assets/image/product-detail/pelleter.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .pelleter-description {
      width: 100%;
      padding: 3.75rem 18%;
      .parameter {
        width: 100%;
        margin: 2rem 0 3rem;
        .parameter-item {
          display: flex;
          height: 29rem;
          &:first-of-type {
            margin-bottom: 3rem;
          }
          .parameter-box {
            width: 48%;
            margin-right: 2%;
            margin-bottom: 1.5rem;
            &:nth-of-type(2n) {
              margin-right: 0;
            }
          }
          .parameter-one{
             background-image: url("../../../assets/image/pelleter/pelleter-1.png");
             background-repeat: no-repeat;
             background-size: cover;
             background-position: center center;
             border-radius: 2rem;
          }
          .parameter-two{
             background-image: url("../../../assets/image/pelleter/pelleter-2.png");
             background-repeat: no-repeat;
             background-size: cover;
             background-position: center center;
             border-radius: 2rem;
          }
          .parameter-text {
            padding: 10rem 3rem;
            .title {
              font-size: 1.8rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .detail {
              font-size: 1.3rem;
              font-family: "OPlusSans3-Regular";
              line-height: 2.5rem;
            }
          }
        }
      }
      .characteristic {
        margin: auto;
        .feature-box {
          .feature-item {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 3rem;
            .left {
              width: 8%;
              margin-right: 2rem;
              img {
                width: 100%;
              }
            }
            .right {
              width: 92%;
              .title {
                font-size: 1.4rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 1rem;
              }
              .desc {
                font-size: 1.2rem;
                font-family: "OPlusSans3-Regular";
              }
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>